.home {
  $bg-color: #f2f2f2;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  height: 100vh;
  background-color: $bg-color;

  padding: 24px;
  box-sizing: border-box;
  overflow: hidden;
}
