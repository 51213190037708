@import '../../styles/base.scss';

$terminal-font-size: 14px;
$border-radiuses: 8px;

.terminal {
  display: none;

  @include bp(desktop) {
    display: flex;
    flex-direction: column;
    width: 550px;
    height: 350px;
    background-color: black;
    border-radius: $border-radiuses;
    box-shadow: 0px 9px 13px 1px #00000054;
    box-shadow: 0px 9px 20px 20px #0000002b;
    margin-top: 32px;

  &__navbar {
    width: 100%;
    height: 52px;
    border-top-left-radius: $border-radiuses;
    border-top-right-radius: $border-radiuses;

    &-close {
      $size: 14px;
      display: block;
      width: $size;
      height: $size;
      margin-left: auto;
      margin-right: 12px;
      margin-top: 12px;
      border-radius: 50%;
      background-color: #ffc83d;
    }
  }

  &__command {
    color: #6fb96f;
  }

  &__screen {
    font-size: $terminal-font-size;
    font-family: monospace;
    color: lime;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 90%;

    &-row {
      padding: 8px 16px;
      margin-bottom: 8px;
    }
  }

  &__actions {
    padding: 6px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    &-prefix {
      font-size: $terminal-font-size;
      font-family: monospace;
      color: #ffc83d;
    }

    &-command {
      flex-grow: 1;
      font-size: $terminal-font-size;
      font-family: monospace;
      padding: 12px;
      outline: none;
      background: black;
      color: lime;
      border: none;
    }
  }

  &__cursor {
    width: 8px;
    height: 16px;
    background-color: lime;
  }
  }

  ::-webkit-scrollbar {
    width: 4px;  /* Remove scrollbar space */
    height: 16px;
    background: transparent;  /* Optional: just make scrollbar invisible */
  }

  ::-webkit-scrollbar-thumb {
      background: #ffc83d;
  }
    
}
