@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;800&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, h1, h2, h3, h4, ul, li {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

img {
  max-width: 100%;
}
