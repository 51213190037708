$breakpoints: (
  'tablet':  767px,
  'desktop': 1024px,
  'desktop-large':  1440px
) !default;

// @author Hugo Giraudel
// https://css-tricks.com/snippets/sass/mixin-manage-breakpoints/
@mixin bp($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}