.animated-button {
  border: black;
  border-radius: 6px;
  background-color: #ffd978;
  color: black;
  cursor: pointer;
  font-size:  14px;
  outline: none;
  padding: 12px 14px;
  text-decoration: none;
  position: absolute;
  bottom: -14px;
  right: 28px;
  display: flex;
  align-items: center;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}