@import '../../styles/base.scss';

.lazy-image-container {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  background: gray;
  border-radius: 8px;

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

.lazy-image {
  opacity: 0;
  width: 100%;
  height: auto;
  background-color: #dadada;

  &--loaded {
    background-color: unset;
    position: relative;
    opacity: 0;
    animation: fadeIn cubic-bezier(0.20, 1, 0.30, 1) 1;
    animation-fill-mode: forwards;
    animation-duration: 0.7s;
    animation-delay: 0.1s;
  }
}
