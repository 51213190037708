@import '../../styles/base.scss';

.onboarding {
  color: #000000;

  &__name {
    font-size: 46px;
    line-height: 70px;
    color:#000000;
    margin-bottom: $gap;

    @include bp(tablet) {
      font-size: 72px;
      line-height: 90px;
    }
    
    &--heavy {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__waving-hand {
    display: inline-block;
    animation-delay: 1.5s;
    animation-duration: 2s;
    animation-name: wave-hand;
  }

  &__teaser {
    display: none;

    @include bp(desktop) {
      display: block;
      font-size: 18px;
      margin: $gap * 2 0;
   
      &-command {
        font-weight: bold;
      }
    }
  }

  &__spotahome {
    cursor: pointer;
    width: 34px;
    vertical-align: middle;
  }

  &__occupation {
    color: $gray;
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: $gap * 2;

    @include bp(tablet) {
      font-size: 24px;
      line-height: 36px;
    }

    @include bp(desktop) {
      margin-bottom: 0;
    }
  }

  &__information {
    &-icon {
      width: 30px;
      vertical-align: middle;
      margin-right: 4px;
    }

    &-socials {
      font-size: 18px;

      @include bp(tablet) {
        font-size: 18px;
      }

      * + * {
        margin-left: 48px;
      }

      &-link {
        color: black;
        text-decoration: none;

      }
    }
  }
}
