@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;800&display=swap);
@-webkit-keyframes wave-hand{from{transform:rotate(0deg)}33%{transform:rotate(-20deg)}66%{transform:rotate(20deg)}to{transform:rotate(0deg)}}@keyframes wave-hand{from{transform:rotate(0deg)}33%{transform:rotate(-20deg)}66%{transform:rotate(20deg)}to{transform:rotate(0deg)}}@-webkit-keyframes fadeIn{from{opacity:0}to{opacity:1}}@keyframes fadeIn{from{opacity:0}to{opacity:1}}.onboarding{color:#000}.onboarding__name{font-size:46px;line-height:70px;color:#000;margin-bottom:24px}@media(min-width: 767px){.onboarding__name{font-size:72px;line-height:90px}}.onboarding__name--heavy{font-weight:bold;text-transform:uppercase}.onboarding__waving-hand{display:inline-block;-webkit-animation-delay:1.5s;animation-delay:1.5s;-webkit-animation-duration:2s;animation-duration:2s;-webkit-animation-name:wave-hand;animation-name:wave-hand}.onboarding__teaser{display:none}@media(min-width: 1024px){.onboarding__teaser{display:block;font-size:18px;margin:48px 0}.onboarding__teaser-command{font-weight:bold}}.onboarding__spotahome{cursor:pointer;width:34px;vertical-align:middle}.onboarding__occupation{color:#b0b0b0;text-align:left;font-size:18px;line-height:24px;margin-bottom:48px}@media(min-width: 767px){.onboarding__occupation{font-size:24px;line-height:36px}}@media(min-width: 1024px){.onboarding__occupation{margin-bottom:0}}.onboarding__information-icon{width:30px;vertical-align:middle;margin-right:4px}.onboarding__information-socials{font-size:18px}@media(min-width: 767px){.onboarding__information-socials{font-size:18px}}.onboarding__information-socials *+*{margin-left:48px}.onboarding__information-socials-link{color:#000;text-decoration:none}
.animated-button{border:#000;border-radius:6px;background-color:#ffd978;color:#000;cursor:pointer;font-size:14px;outline:none;padding:12px 14px;text-decoration:none;position:absolute;bottom:-14px;right:28px;display:flex;align-items:center;-webkit-backface-visibility:hidden;backface-visibility:hidden;-webkit-font-smoothing:subpixel-antialiased}
@-webkit-keyframes wave-hand{from{transform:rotate(0deg)}33%{transform:rotate(-20deg)}66%{transform:rotate(20deg)}to{transform:rotate(0deg)}}@keyframes wave-hand{from{transform:rotate(0deg)}33%{transform:rotate(-20deg)}66%{transform:rotate(20deg)}to{transform:rotate(0deg)}}@-webkit-keyframes fadeIn{from{opacity:0}to{opacity:1}}@keyframes fadeIn{from{opacity:0}to{opacity:1}}.lazy-image-container{width:100%;padding-bottom:56.25%;position:relative;background:gray;border-radius:8px}.lazy-image-container>img{position:absolute;top:0;left:0;width:100%;height:100%}.lazy-image{opacity:0;width:100%;height:auto;background-color:#dadada}.lazy-image--loaded{background-color:unset;position:relative;opacity:0;-webkit-animation:fadeIn cubic-bezier(0.2, 1, 0.3, 1) 1;animation:fadeIn cubic-bezier(0.2, 1, 0.3, 1) 1;-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards;-webkit-animation-duration:.7s;animation-duration:.7s;-webkit-animation-delay:.1s;animation-delay:.1s}
.project-details__title{text-align:left;font-size:82px}.project-details__container{position:relative}.project-details__demo{margin-top:12px}.project-details__image{width:100%;border-radius:8px}.project-details__button{z-index:2}.project-details__description{font-size:14px;margin-top:24px}.project-details__description-badge{padding:12px 8px;border-radius:4px;background-color:#e5e5e5;margin-right:8px}
.projects__project-title{text-align:right;font-size:60px}.projects__project-description{margin-top:24px}.projects__project-demo{margin-top:12px}.projects__project-demo-btn{font-size:14px;outline:none;border:#000;border-radius:6px;background-color:#ffd978;color:#000;padding:12px 14px;text-decoration:none;cursor:pointer;position:absolute;bottom:-14px;right:28px;display:flex;align-items:center;-webkit-backface-visibility:hidden;backface-visibility:hidden;-webkit-font-smoothing:subpixel-antialiased}.projects__project-star{margin-left:24px}.projects__project-header{display:flex;align-items:center;margin-bottom:18px}.projects__project-image{width:100%;border-radius:8px}.projects__project-image-wrapper{position:relative}.projects__footer{position:absolute;bottom:40px;width:380px;left:-10px}.project__technology-badge{font-size:14px;padding:8px;border-radius:4px;background-color:#e5e5e5;margin-right:8px}.icon-eye{width:20px;margin-right:8px;vertical-align:middle}
@-webkit-keyframes wave-hand{from{transform:rotate(0deg)}33%{transform:rotate(-20deg)}66%{transform:rotate(20deg)}to{transform:rotate(0deg)}}@keyframes wave-hand{from{transform:rotate(0deg)}33%{transform:rotate(-20deg)}66%{transform:rotate(20deg)}to{transform:rotate(0deg)}}@-webkit-keyframes fadeIn{from{opacity:0}to{opacity:1}}@keyframes fadeIn{from{opacity:0}to{opacity:1}}@media(min-width: 1024px){.sliding-panel{width:40%}}
@-webkit-keyframes wave-hand{from{transform:rotate(0deg)}33%{transform:rotate(-20deg)}66%{transform:rotate(20deg)}to{transform:rotate(0deg)}}@keyframes wave-hand{from{transform:rotate(0deg)}33%{transform:rotate(-20deg)}66%{transform:rotate(20deg)}to{transform:rotate(0deg)}}@-webkit-keyframes fadeIn{from{opacity:0}to{opacity:1}}@keyframes fadeIn{from{opacity:0}to{opacity:1}}.terminal{display:none}@media(min-width: 1024px){.terminal{display:flex;flex-direction:column;width:550px;height:350px;background-color:#000;border-radius:8px;box-shadow:0px 9px 13px 1px #00000054;box-shadow:0px 9px 20px 20px #0000002b;margin-top:32px}.terminal__navbar{width:100%;height:52px;border-top-left-radius:8px;border-top-right-radius:8px}.terminal__navbar-close{display:block;width:14px;height:14px;margin-left:auto;margin-right:12px;margin-top:12px;border-radius:50%;background-color:#ffc83d}.terminal__command{color:#6fb96f}.terminal__screen{font-size:14px;font-family:monospace;color:lime;overflow-y:scroll;display:flex;flex-direction:column-reverse;width:100%;height:90%}.terminal__screen-row{padding:8px 16px;margin-bottom:8px}.terminal__actions{padding:6px 10px;display:flex;flex-direction:row;flex-wrap:wrap;align-items:center}.terminal__actions-prefix{font-size:14px;font-family:monospace;color:#ffc83d}.terminal__actions-command{flex-grow:1;font-size:14px;font-family:monospace;padding:12px;outline:none;background:#000;color:lime;border:none}.terminal__cursor{width:8px;height:16px;background-color:lime}}.terminal ::-webkit-scrollbar{width:4px;height:16px;background:transparent}.terminal ::-webkit-scrollbar-thumb{background:#ffc83d}
.footer{position:absolute;bottom:24px;left:0;width:100%;text-align:center}.footer span>div{box-shadow:0px 9px 13px 1px #00000054}
.home{display:flex;align-items:center;justify-content:space-evenly;width:100vw;height:100vh;background-color:#f2f2f2;padding:24px;box-sizing:border-box;overflow:hidden}
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, h1, h2, h3, h4, ul, li {
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
}

img {
  max-width: 100%;
}

