@keyframes wave-hand {
  from {
    transform: rotate(0deg); 
  }

  33% {
    transform: rotate(-20deg);
  }

  66% {
    transform: rotate(20deg);
  }

  to {
    transform: rotate(0deg)
  }
}

@keyframes fadeIn {
 from {
  opacity: 0
 }
  
 to {
  opacity: 1
 }
}