.projects {
  &__project {
    &-title {
      text-align: right;
      font-size: 60px;
    }

    &-description {
      margin-top: 24px;
    }

    &-demo {
      margin-top: 12px;

      &-btn {
        font-size:  14px;
        outline: none;
        border: black;
        border-radius: 6px;
        background-color: #ffd978;
        color: black;
        padding: 12px 14px;
        text-decoration: none;
        cursor: pointer;
        position: absolute;
        bottom: -14px;
        right: 28px;
        display: flex;
        align-items: center;

        backface-visibility: hidden;
        -webkit-font-smoothing: subpixel-antialiased;
      }    
    }

    &-star {
      margin-left: 24px;
    }

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
    }

    &-image {
      &-wrapper {
        position: relative;
      }
      width: 100%;
      border-radius: 8px;
    }
  }

  &__footer {
    position: absolute;
    bottom: 40px;
    width: 380px;
    left: -10px;
  }
}

.project__technology {
  &-badge {
    font-size: 14px;
    padding: 8px;
    border-radius: 4px;
    background-color: #e5e5e5;
    margin-right: 8px;
  }
}

.icon-eye {
  width: 20px;
  margin-right: 8px;
  vertical-align: middle;
}
